<template>
  <div class="info-success">
    <div class="top">
      <!-- <img src="@/assets/pdmInfo/success.png" /> -->
      <p>{{ payResult }}</p>
    </div>
    <!-- <div class="button">完成</div> -->
    <van-overlay :show="loading">
      <van-loading size="40" vertical class="loadingDiv"
        >支付结果查询中...</van-loading
      >
    </van-overlay>
  </div>
</template>
<script>
import { Toast, Dialog, Loading, Overlay } from "vant";
import { selectPayState } from "@/api";
export default {
  name: "rights",
  data() {
    return {
      payNo: "",
      loading: true,
      payResult: "",
      payShow: false,
    };
  },
  created() {
    this.payNo = this.$route.query.payNo;
    this.onHandlePay();
  },
  methods: {
    goPage(page) {
      this.$router.push(page);
    },
    goPersonal() {
      this.$router.push("/personal");
    },
    async onHandlePay() {
      const res = await selectPayState({
        payNo: this.payNo,
      });
      if (res.code == 200) {
        if (res.data.tradeState == "SUCCESS") {
          this.payResult = "支付成功";
          this.payShow = true;
          this.loading = false;
        } else {
          this.payResult = "微信支付失败";
          this.$toast("微信支付失败");
          this.loading = false;
        }
      } else {
        his.payResult = "微信支付失败";
        this.$toast("微信支付失败");
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.loadingDiv {
  position: absolute;
  left: 42%;
  top: 40%;
}
.info-success {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url(../../assets/pdmInfo/bg-success.png);
  background-position: top left;
  background-size: 100% auto;
  .top {
    text-align: center;
    padding-top: 150px;
    img {
      width: 128px;
      margin-top: 150px;
      transform: translate3d(10px, 0, 0);
    }
    p {
      font-weight: 600;
      font-size: 20px;
      color: #000;
    }
  }
  .button {
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 30px;
    border-radius: 12px;
    background: rgba(21, 177, 129, 1);
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    padding: 13px 0;
    text-align: center;
  }
}
</style>
