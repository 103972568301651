<template>
    <div class="distribute">
        <div v-if="mainPerson" class="distribute-header flex flex-align-center flex-space-between">
            <div class="distribute-header_left">
                <h4>{{ mainPerson.personName }}</h4>
                <p>{{ mainPerson.personPhone }}</p>
            </div>
            <div class="distribute-header_right flex flex-align-center">
                <p>{{ mainPerson.privilgeCount }}<span>次</span></p>
            </div>
        </div>
        <dl v-for="(item, index) in otherPerson" :key="index" class="flex flex-align-center flex-space-between">
            <dt>
                <h4>{{ item.personName }}<span v-if="item.privilgeCount > 0">剩余{{ item.privilgeCount }}次</span></h4>
                <p>手机号：{{ item.personPhone }}</p>
            </dt>
            <dd class="flex flex-align-center">
                <van-stepper min="0" :max="item.max" @plus="onHandlePlus(index)" @minus="onHandleMinus(index)" v-model="item.count" input-width="40px" button-size="24px"/>
            </dd>
        </dl>
        <div class="fixed bottom">
            <div @click="onSendMessage">确定</div>
        </div>
        <div class="modal" v-if="dialog">
            <div class="mask"></div>
            <div class="container flex flex-align-center flex-space-between">
                <div class="modal-content">
                    <h4>二次确认</h4>
                    <p>邀请码已发送至 {{ mainPerson.personPhone }}</p>
                    <p class="resend" :class="[{'error': btnStatus}]" @click="onSendMessage">{{ btnText }}</p>
                    <div class="number-content">
                        <label class="simple-input-content" for="code">
                            <span>{{code.slice(0,1)}}</span>
                            <span>{{code.slice(1,2)}}</span>
                            <span>{{code.slice(2,3)}}</span>
                            <span>{{code.slice(3,4)}}</span>
                            <span>{{code.slice(4,5)}}</span>
                            <span>{{code.slice(5,6)}}</span>
                        </label>
                        <input v-model.trim="code" @input="onHandleInput" type="number">
                    </div>
                    <div class="dialog-btn" :class="[{'submit' : submitFlag}]" @click="onHandleSubmit">确定</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { onFetchMessage, onDistributePdm } from '@/api';
import { Toast } from 'vant';
export default {
    data() {
        return {
            dialog: false,// 验证码弹窗
            code: '', // 验证码
            btnStatus: false,
            time: 60,
            btnText: '',
            submitFlag: false, // 是否可提交
            submitAgain: false,
            max: 0, // 最大
            mainPerson: null, // 头部一个人
            otherPerson: [], // 下面列表，其他人
            personOtherFamily: [],
            personPrivilegeId: ''
        }
    },
    watch: {
        "code": {
            handler(value) {
              if (value.length == 6) {
                this.submitFlag = true;
              } else {
                this.submitFlag = false;
              }
            },
          }
    },
    created() {
        this.personPrivilegeId = this.$route.query.personPrivilegeId;
        this.personOtherFamily = JSON.parse(localStorage.getItem('personOtherFamily'));
        console.log('this.personOtherFamily', this.personOtherFamily);
        this.onHandleFilterMainPerson();
    },
    methods: {
        onHandleFilterMainPerson() {
            // this.mainPerson = this.personOtherFamily.filter(item => item.relationType == 1)[0];
            // console.log('mainPerson', this.mainPerson);

            // this.otherPerson = this.personOtherFamily.filter(item => item.relationType != 1);
            this.personOtherFamily.forEach(item => {
                if (item.relationType == 1) {
                    this.mainPerson = item;
                    this.max = item.privilgeCount;
                } else {
                    item.count = 0;
                    item.max = this.mainPerson.privilgeCount;
                    this.otherPerson.push(item);
                }
            })
        },
        // 增加
        onHandlePlus(index) {
            this.otherPerson[index].count += 1;
            this.otherPerson.forEach((item, i) => {
                if (index != i) {
                    this.otherPerson[i].max = --item.max;
                }
            });
            this.$set(this, 'otherPerson', this.otherPerson);
            this.$forceUpdate();
        },
        // 减少
        onHandleMinus(index) {
            if (this.otherPerson[index].count == 0) return false;
            this.otherPerson[index].count -= 1;
            this.otherPerson.forEach((item, i) => {
                if (index != i) {
                    this.otherPerson[i].max = ++item.max;
                }
            });
            this.$set(this, 'otherPerson', this.otherPerson);
            this.$forceUpdate();
            console.log('this.otherPerson', this.otherPerson)
        },
        onHandleInput(e) {
            const value = e.target.value;
            if (value.length > 6) {
                this.code = value.slice(0, 6);
                console.log('code', this.code);
            }
        },
        // 发送验证码
        async onSendMessage() {
            try {
                const res = await onFetchMessage({
                    userPhone: this.mainPerson.personPhone,
                    publicType: localStorage.getItem('publicType')
                });
                Toast('短信已发送');
                console.log('res', res);
                this.btnStatus = false;
                this.dialog = true;
                let timer = setInterval(() => {
                    if (--this.time > 0) {
                        this.btnText = `${this.time}s`;
                    } else {
                        this.btnText = '重新发送';
                        clearInterval(timer);
                        this.btnStatus = true;
                        this.time = 60;
                    }
                }, 1000);
            } catch(err) {
                this.btnStatus = true;
                console.log('err', err);
            }
        },
        // 提交
        async onHandleSubmit() {
            if (this.submitFlag) {
                if(this.submitAgain){
                    return ;
                }
                this.submitAgain = true;
                // 提交
                try {
                    let param = {
                        "personPrivilegeId": this.personPrivilegeId,
                        "securityCode": this.code,
                        "pdmItemList": [],
                    }
                    let arr = [];
                    this.otherPerson.forEach(item => {
                        arr.push({
                            privilegeId: item.personId,
                            privilgeCount: item.count
                        })
                    })
                    param.pdmItemList = arr;
                    const res = await onDistributePdm(param);
                    if (res.code == 200) {
                        this.submitAgain = false;
                        this.$router.back();
                    } else {
                        this.submitAgain = false;
                        Toast(res.msg);
                    }
                } catch(err) {
                    this.submitAgain = false;
                    console.log(err);
                }
            }
        }
    }
}
</script>
<style lang="less" scoped>
.distribute {
    padding: 10px 15px;
    &-header {
        padding: 15px;
        background: #FF2546 url(../../../assets/pdm/bg-banner.png);
        background-position: right;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 6px;
        &_left {
            h4 {
                color: #fff;
                font-size: 16px;
            }
            p {
                margin-top: 7px;
                color: rgba(255, 255, 255, .9);
                font-size: 14px;
            }
        }
        &_right {
            font-size: 23px;
            color: #fff;
            p {
                display: inline-block;
                span {
                    font-size: 12px;
                }
            }
        }
    }
    dl {
        margin-top: 10px;
        padding: 14px 15px;
        border-radius: 6px;
        background: url(../../../assets/pdm/bg-item.png);
        background-repeat: no-repeat;
        background-size: contain;
        dt {
            h4 {
                font-size: 16px;
                color: #000;
                span {
                    display: inline-block;
                    background-color: #FFD9AC;
                    color: #961E10;
                    font-size: 10px;
                    padding: 0 5px;
                    margin-left: 5px;
                    border-radius: 5px 1px 5px 1px;
                }
            }
            p {
                margin-top: 7px;
                color: #777;
                font-size: 11px;
            }
        }
        dd {
            ::v-deep .van-stepper{
                background: #EE3941;
                border-radius: 8px;
                overflow: hidden;
                position: relative;
                line-height: 30px;
                height: 30px;
                font-weight: bold;
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 25%;
                    width: 1px;
                    height: 50%;
                    background-color: #FC494E;
                }
                &:before {
                    left: 24px;
                }
                &:after {
                    right: 24px;
                }
            }
            ::v-deep .van-stepper__minus,
            ::v-deep .van-stepper__plus,
            ::v-deep .van-stepper__input {
                background: #EE3941;
                color: #fff;
                position: relative;
            }
        }
    }
    .bottom {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px -6px 20px 0px rgba(240,240,240,0.8);
        padding: 10px 30px;
        div {
            background-color: #FD3D45;
            color: #fff;
            text-align: center;
            line-height: 44px;
            height: 44px;
            border-radius: 22px;
            font-size: 16px;
        }
    }
}
.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.mask,
.container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.mask {
    background-color: rgba(0, 0, 0, .6);
}
.container {
    padding-left: 44px;
    padding-right: 44px;
    .modal-content {
        flex: 1;
        background-color: #fff;
        border-radius: 5px;
        padding: 30px 18px;
        text-align: center;
        h4 {
            font-size: 18px;
            color: #000;
        }
        p {
            font-size: 15px;
            color: #747273;
            margin-top: 15px;
            &.resend {
                margin-top: 5px;
                &.error {
                    color: #FD3D45;
                }
            }
        }
    }
}
.number-content {
    position: relative;
    height: 35px;
    margin: 25px 0 44px;
    input {
        background-color: transparent;
        color: transparent;
        border: none;
        position: absolute;
        height: 35px;
        left: 0;
        right: 0;
        z-index: 2;
    }
    .simple-input-content {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        span {
            display: inline-block;
            text-align: center;
            height: 35px;
            line-height: 35px;
            width: 35px;
            border-radius: 7px;
            background-color: #F4F4F4;
            font-size: 16px;
        }
    }
}
.dialog-btn {
    line-height: 44px;
    color: #fff;
    font-size: 15px;
    border-radius: 22px;
    background-color: #AAAAAA;
    &.submit {
        background-color: #FD3D45;
    }
}
</style>