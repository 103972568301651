<template>
    <div class="personalNew">
        
    </div>
</template>
<script>
import { selUserLoginInfo,loginOut } from '@/api';
export default {
    name: 'personalNew',
    data() {
        return {
            userPhone: null,
            publicType: null,
            pdmJumpUrl: ''
        }
    },
    created() {
        this.publicType = localStorage.getItem('publicType');
        this.init();
    },
    methods: {
        goPage(page) {
            if(page == 'appointmentList'){
                if(this.publicType == 1){
                    window.location.href = this.pdmJumpUrl;
                }else{
                    this.$router.push(page);
                }
            }else{
                this.$router.push(page);
            }
        },
        async logout(){
            const res = await loginOut({
                publicType : this.publicType
            });
            if (res.code == 200) {
                localStorage.removeItem('token');
                this.goPage('loginNew');
            }
        },
        async init() {
            const res = await selUserLoginInfo({
                publicType : 1
            });
            if (res.code == 200) {
                if(res.data.pdmJump3Url != null){
                    window.location.href = res.data.pdmJump3Url;
                }else{
                    this.$router.push("/personalNew?publicType=1");
                }
            }
        }
    }
}
</script>
<style lang="less" scoped>
.personalNew {
    &-header {
        width: 100%;
        height: 240px;
        background: url(../../assets/center-new-bg.png);
        background-repeat: no-repeat;
        background-size: 100%;
        text-align: center;
        padding: 40px 15px 0;
        text-align: left;
        h4 {
            color: #fff;
            font-size: 18px;
            margin-bottom: 20px;
        }
        &_content {
            border-radius: 8px;
            background-color: #fff;
            padding: 20px 15px;
            h4 {
                color: #000;
                font-size: 17px;
                margin-bottom: 10px;
            }
            div {
                color: #747273;
            }
        }
    }
    &-content {
        width: 90%;
        margin: -20px auto 0;
        padding: 20px 0 5px 15px;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0px 0px 60px 0px rgba(0,0,0,0.03);
        h4 {
            font-size: 16px;
        }
        .icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            transform: translateY(-1px);
        }
        .van-cell {
            font-size: 14px;
            padding: 20px 15px 20px 0;
            align-items: center;
        }
    }
}
.logoutDiv{
    position: fixed;
    width: 100%;
    height: 44px;
    bottom: 50px;
    left: 0;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    p{
        width: 100%;
        height: 100%;
        line-height: 44px;
        color: #fff;
        background-color: #FD3D45;
        font-size: 16px;
        border-radius: 22px;
        text-align: center;
    }
}
.icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}
</style>