<template>
    <div v-if="pageShow" class="list">
        <van-tabs @click="onHandleChange">
            <van-tab v-for="(item, index) in menu" :title="item.label" :key="index">
                <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                    <div>
                        <div class="list-content" v-if="list.length > 0">
                            <div class="list-item" v-for="(item, index) in list" :key="index">
                                <dl>
                                    <dt>
                                        <img :src="item.privilegeImgUrl" />
                                    </dt>
                                    <dd>
                                        <div class="flex title">
                                            <p class="flex-1">{{ item.privilegeName }}</p>
                                            <span>{{ item.applyState }}</span>
                                        </div>
                                        <div class="flex content">
                                            <div class="flex-1">
                                                <p>申请时间：{{ item.applyDate }}</p>
                                                <p>预约人：{{ item.personName }}</p>
                                            </div>
                                            <div>
                                                <van-button round type="info" size="small" @click="onHandleDetail(item)">预约详情</van-button>
                                            </div>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                        <van-empty
                            v-else
                            class="custom-image"
                            :image="require('@/assets/empty.png')"
                            description="预约列表为空"
                        />
                    </div>
                </van-pull-refresh>
            </van-tab>
        </van-tabs>
    </div>
</template>
<script>
import { onFetchMyApplyList,onDispatchMyApply } from '@/api';
import { Toast } from 'vant';
export default {
    data() {
        return {
            isLoading: false,
            publicType: null,
            pageShow: false,
            menu: [{
                value: 0,
                label: '全部'
            }, {
                value: 1,
                label: '提交申请'
            }, {
                value: 2,
                label: '申请取消'
            }, {
                value: 3,
                label: '进行中'
            },{
                value: 4,
                label: '审核拒绝'
            },{
                value: 5,
                label: '已完成'
            }],
            list: [],
            applyState: 0, // 0 - 全部， 1 - 提交预约 ，2 - 服务取消
        }
    },
    created() {
        this.publicType = localStorage.getItem('publicType');
        this.init();
    },
    methods: {
        async init() {
            this.pageShow = true;
            try {
                const res = await onFetchMyApplyList({
                    applyState: this.applyState
                });
                if (res.code == 200) {
                    this.list = res.data;
                }
                console.log('res', res);
            } catch(err) {
                console.log('err', err);
            }
        },
        onHandleChange(index) {
            console.log('item', index);
            this.applyState = index;
            this.init();
        },
        // 详情
        onHandleDetail(item) {
            this.$router.push(`/appointmentDetail?id=${item.applyId}`);
        },
        onRefresh() {
            setTimeout(() => {
                this.isLoading = false;
            }, 1000);
        }
    }
}
</script>
<style lang="less" scoped>
.list {
    .van-tabs {
        height: 100vh;
        overflow: hidden;
    }
    .van-pull-refresh {
        height: calc(100vh - 45px);
        overflow-y: auto;
    }
    ::v-deep .van-tab.van-tab--active{
        color: rgb(0, 93, 204);
    }
    ::v-deep .van-tabs__line {
        background-color: rgb(0, 93, 204) !important;
    }
    ::v-deep .van-tabs__wrap {
        box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    }
    ::v-deep .custom-image {
        padding-top: 100px;
        .van-empty__image {
            width: 260px;
            height: auto;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    .list-content {
        padding: 10px 15px;
        .list-item {
            background: #fff;
            margin-bottom: 10px;
            padding: 20px 15px;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, .1);
            dl {
                display: flex;
                dt {
                    img {
                        width: 60px;
                        height: 60px;
                        margin-right: 20px;
                    }
                }
                dd {
                    flex: 1;
                    .flex {
                        justify-content: space-between;
                    }
                    p {
                        margin-bottom: 10px;
                    }
                    .title {
                        align-items: center;
                        p {
                            font-size: 18px;
                            color: #333;
                            font-weight: 800;
                        }
                        span {
                            font-size: 12px;
                            color: #4a7cb0;
                            background: #f5f8fb;
                            padding: 3px 5px;
                            border-radius: 10px;
                        }
                    }
                    .content {
                        p {
                            font-size: 14px;
                            color: #666;
                        }
                    }
                }
            }
        }
    }
}
</style>