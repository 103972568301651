import { render, staticRenderFns } from "./distribute.vue?vue&type=template&id=049d7b66&scoped=true&"
import script from "./distribute.vue?vue&type=script&lang=js&"
export * from "./distribute.vue?vue&type=script&lang=js&"
import style0 from "./distribute.vue?vue&type=style&index=0&id=049d7b66&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "049d7b66",
  null
  
)

export default component.exports