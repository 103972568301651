<template>
    <div class="right-person">
        <!-- 列表头 -->
        <div class="right-person_header">
            <div class="right-person_header__list">
                <span
                    v-for="item in headerList"
                    :key="item.id"
                    :class="[{'active': activeIndex === item.id}]"
                    @click="onHandleChangeMenu(item)"
                >{{ item.label }}</span>
            </div>
        </div>
        <!-- 有权益 -->
        <div v-if="rightsVisable" class="bg">
            <div v-for="(item, itemIndex) in rightsList" :key="itemIndex">
                <div class="header">
                    <div class="banner" @click="goPage(item)">
                        <img :src="item.productCardImgUrl" />
                        <img v-if="item.productName!='HPV权益卡'" class="download" src="@/assets/download.png" />
                        <span v-if="item.productName!='HPV权益卡'" class="banner-card">{{ item.privilegeState | onFilterStatus }}</span>
                        <p v-if="item.productName!='HPV权益卡'" :class="[{'banner-bottom': true}]">
                            <span>权益人：{{ item.personName }}</span>
                            <span>最长有效期至：{{ item.privilegeExpire }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 无权益 -->
        <div v-else>
            <div class="right-empty" v-if="!loading">
                <van-empty image-size="200px">
                    <div slot="description" class="empty-bottom-text text-center">
                       <p>您暂无此权益</p>
                       <p>请激活权益后再次尝试</p>
                       <!--<p>请激活权益后再次尝试</p>-->
                    </div>
                </van-empty>
            </div>
        </div>
        <van-popup
                        v-model="show"
                        round
                        overlay-class="info-mask"
                        closeable
                        close-icon="close">
                        <div class="info-mask_content">
                            <h4 class="text-center">详情</h4>
                            <p><span>姓名</span><span>{{ personInfo.personName }}</span></p>
                            <p><span>证件号</span><span>{{ personInfo.credentialsNumber }}</span></p>
                            <p><span>手机号</span><span>{{ personInfo.personPhone }}</span></p>
                            <p><span>首次激活</span><span>{{ personInfo.createTime }}</span></p>
                            <p><span>最长有效期</span><span>{{ personInfo.privilegeExpire }}</span></p>
                        </div>
                    </van-popup>
    </div>
</template>
<script>
import { Toast } from 'vant';
import { onFetchRightsByUser } from '@/api';
export default {
    name: 'rightPersons',
    data() {
        return {
            loading: true, // loading页面
            activeIndex: '',
            headerList: [{
                id: '',
                label: '全部'
            },{
                id: 1,
                label: '已激活'
            }, {
                id: 5,
                label: '未生效'
            }, {
                id: 2,
                label: '已失效'
            }],
            show: false,
            rightsVisable: false,
            rightsList: [],
            publicType: null,
            toastShow: false, // 是否展示药品种类弹窗
            personItem: null, // 受益人信息
            personShow: false, // 添加受益人
            personInfo: '',
            personName: '',
            credentialsNumber: '',
            relationType: '2',
            personId: '',
            drugList: [], // 药品列表
        }
    },
    created() {
        this.publicType = localStorage.getItem('publicType');
        this.init();
    },
    filters: {
        // 本人关系
        onFilterRelative(val) {
            let msg = '';
            switch(val) {
                case 1:
                    msg = '本人';
                    break;
                case 2:
                    msg = '配偶';
                    break;
                default:
                    msg = '子女';
            }
            return msg;
        },
        // 状态
        onFilterStatus(val) {
            let msg = '';
            switch(val) {
                case 1:
                    msg = '已激活';
                    break;
                case 2:
                    msg = '已失效';
                    break;
                case 3:
                    msg = '已使用';
                    break;
                case 4:
                    msg = '已激活';
                    break;
                case 5:
                    msg = '未生效';
                    break;
            }
            return msg;
        }
    },
    methods: {
        showPopup() {
            this.toastShow = true;
        },
        // 切换菜单
        onHandleChangeMenu(item) {
            if (item.id === this.activeIndex) return;
            this.activeIndex = item.id;
            this.init();
        },
        goPage(item) {
            if(item.remark.publicType == 2 && !item.remark.isSelfSupport){
                let forPrivilegeList = item.privilegeList;
                forPrivilegeList.forEach((v, i) => {
                    if(v.isUrlPrivilege) {
                        window.location.href = v.privilegeUrl;
                        return;
                    }
                })
            }else{
                this.$router.push('/rightsPerson?personPrivilegeId=' + item.personPrivilegeId);
            }
        },
        onShow(item,index) {
            this.personInfo = item;
            this.show = true;
        },
        // 获取权益
        async init() {
            this.loading = true;
            try {
                const res = await onFetchRightsByUser({
                    publicType: this.publicType,
                    privilegeState: this.activeIndex
                });
                if (res.code == 200) {
                    if(this.publicType == 2) {
                        let forPrivilegeList = res.data[0].privilegeList;
                        forPrivilegeList.forEach((v, i) => {
                            if(v.isUrlPrivilege) {
                                window.location.href = v.privilegeUrl;
                                return;
                            }
                        })
                    }else {
                        this.rightsList = res.data;
                        this.rightsVisable = true;
                        this.rightsList.forEach((v, i) => {
                            this.rightsList[i]['currentIndex'] = 0;
                        })
                        if(this.rightsList.length == 1) {
                            this.goPage(this.rightsList[0]);
                        }
                        
                    }
                } else if (res.code == 2014) {
                    // 无权益
                    this.rightsVisable = false;
                }
            } catch(err) {
                console.log('err', err);
            }
            this.loading = false;
        }
    }
}
</script>
<style scoped lang="less">
::v-deep .van-radio-group--horizontal {
    display: flex;
    .van-radio--horizontal {
        flex: 1;
        font-size: 12px;
    }
}
.table-toast {
    position: relative;
    width: 310px;
    &_max {
        max-height: 300px;
        overflow-y: auto;
    }
    table {
        width: 100%;
        border-collapse:collapse;
        border:none;
        position: relative;
        &.absolute {
            tbody {
                display: none;
            }
        }
        td,
        th {
            padding: 5px;
            font-size: 12px;
            font-weight: normal;
        }
        th {
            padding: 10px 5px;
        }
        thead {
            background: #94d3f4;
            font-size: 16px;
            font-weight: 500;
        }
        tbody {
            tr {
                &:nth-child(even) {
                    background: #f2f2f2;
                }
            }
        }
    }
}
.add-person {
    padding: 20px;
    width: 300px;
}
.van-cell::after {
    border-bottom: 0;
}
.btn-options {
    button {
        margin: 0 10px;
    }
}

::v-deep .info-mask {
    background-color: rgba(0, 0, 0, .3) !important;
    &_content {
        width: 280px;
        padding: 10px 20px;
        h4 {
            margin: 0;
            padding: 10px;
            font-size: 18px;
        }
        p {
            margin-bottom: 10px;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
        }
    }
}
.right-person{
    color: #333;
    background: #f5f5f5;
    padding-top: 50px;
    &_header {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        padding: 10px 10px 0;
        background-color: #fff;
        z-index: 100;
        &__list {
            background: #f5f5f5;
            line-height: 30px;
            border-radius: 30px;
            padding: 5px;
            box-sizing: border-box;
            display: flex;
            span {
                flex: 1;
                font-size: 18px;
                color: #666;
                text-align: center;
                &.active {
                    color: #222;
                    background-color: #fff;
                    border-radius: 30px;
                }
            }
        }
    }
    .bg {
        background-color: #fff;
        padding: 10px;
    }
    .header {
        font-size: 12px;
        .banner {
            width: 300px;
            margin: 0 auto;
            position: relative;
            color: #fff;
            img {
                width: 100%;
            }
            .download {
                width: 54px;
                position: absolute;
                left: 4px;
                top: 24px;
                z-index: 9;
            }
            &-card {
                position: absolute;
                left: 12px;
                top: 25px;
                z-index: 19;
            }
            p {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 28px;
                padding: 0 15px;
                display: flex;
                justify-content: space-between;
                &.banner-bottom {
                    width: 95%;
                    margin-left: 2.5%;
                    bottom: 18px;
                    padding: 7px 15px;
                    background-color: rgba(255, 255, 255, .3);
                }
            }
        }
        &> p {
            color: #005DCC;
            font-weight: 500;
        }
        &.family {
            h2 {
                display: flex;
                align-items: center;
                i {
                    display:inline-block;
                    vertical-align:middle;
                    margin-right:10px;
                    width:4px;
                    height:20px;
                    background:#1989fa;
                    border-radius:2px;
                }
                span {
                    display: inline-block;
                    padding: 5px 10px;
                    font-size: 12px;
                    color: #fff;
                    background: #1989fa;
                    border-radius: 30px;
                    margin-left: 20px;
                }
            }
            .header-span {
                span {
                    display: inline-block;
                    padding: 5px 10px;
                    background: #ccc;
                    border-radius: 30px;
                    margin: 10px 10px 0 0;
                    &.active {
                        color: #fff;
                        background: #1989fa;
                    }
                }
            }
            .header-item {
                padding: 10px 0;
                &>div {
                    display: flex;
                    font-size: 14px;
                    line-height: 24px;
                    p {
                        flex: 1;
                    }
                }
            }
        }
    }
    .notice {
        background: rgba(252, 137, 64, 0.03);
        border-radius: 5px;
        padding: 5px;
        color: #FC8940;
        font-size: 12px;
        margin: 10px auto 0;
        display: flex;
        align-items: center;
        .remind {
            background: #FC8940;
            padding: 2px 5px;
            color: #fff;
            font-size: 10px;
            margin-right: 5px;
            border-radius: 2px;
            text-align: center;
        }
    }
    h2 {
        margin: 10px 0;
        font-size: 18px;
        position: relative;
        padding: 0 10px;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 10%;
            width: 4px;
            height: 80%;
            background: #1989fa;
        }
    }
    &_item {
        margin-bottom: 20px;
        &__title {
            background: #fff;
            border-radius: 10px 10px 0 0;
            padding: 10px;
            font-size: 14px;
            margin-bottom: 10px;
            h4 {
                font-size: 16px;
            }
            .person-info {
                margin-top: 10px;
                img {
                    width: 17px;
                    height: 17px;
                }
            }
        }
        &__content {
            ul {
                li {
                    position: relative;
                    padding: 25px 10px 10px;
                    font-size: 12px;
                    color: grey;
                    margin-bottom: 10px;
                    border-radius: 10px;
                    background: #fff;
                    &.unclick {
                        filter: grayscale(1);
                        opacity: 0.6;
                    }
                    .item-tag {
                        position: absolute;
                        left: 0;
                        top: 0;
                        border-radius: 10px 0 10px 0;
                        overflow: hidden;
                        font-size: 12px;
                        padding: 3px 8px;
                        color: #fff;
                        background-color: #1989fa;
                    }
                    img {
                        margin-top: 10px;
                        width: 64px;
                        height: 64px;
                    }
                    .title {
                        color: #333;
                        font-size: 14px;
                        font-weight: bold;
                        margin-bottom: 10px;
                        flex: 1;
                    }
                    .btn-mini {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    .desc-info {
                        dl {
                            dt, 
                            dd {
                                margin-bottom: 5px;
                            }
                            dt {
                                font-size: 14px;
                                color: #FC8940;
                                img {
                                    margin-top: 0;
                                    margin-right: 5px;
                                    width: 5px;
                                    height: 5px;
                                }
                            }
                            dd {
                                padding: 3px 5px;
                                color: #005DCC;
                                background: #F3F8FF;
                                span {
                                    &::after {
                                        content: '、';
                                    }
                                    &:last-child {
                                        &::after {
                                            content: '';
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
    }
    .right-empty {
        background-color: #fff;
        padding: 40px;
        .empty-bottom-text {
            width: 200px;
            text-align: center;
            font-size: 14px;
            .active {
                color: #1989fa;
            }
        }
    }
}
</style>