<template>
    <div class="serverPdf">
		<img @click="preview(0)" src="https://more-health.oss-cn-beijing.aliyuncs.com/208/drug/0.png">
        <img @click="preview(1)" src="https://more-health.oss-cn-beijing.aliyuncs.com/208/drug/1.png">
        <img @click="preview(2)" src="https://more-health.oss-cn-beijing.aliyuncs.com/208/drug/2.png">
        <img @click="preview(3)" src="https://more-health.oss-cn-beijing.aliyuncs.com/208/drug/3.png">
        <img @click="preview(4)" src="https://more-health.oss-cn-beijing.aliyuncs.com/208/drug/4.png">
    </div>
</template>

<script>
import { contents } from './util';
import { ImagePreview } from 'vant';
import { weixinInit } from '@/utils/wechatUtil.js';
import { selUserPrivilegeDetail  } from '@/api';
export default {
    data() {
        return {
           personName: '',
           idCardNo: '',
           activationCardNumber: '',
           personPhone: '',
           personPrivilegeId: '',
        }
    },
    created() {
    },
    methods: {
        async preview(index) {
            ImagePreview({
            images:[
                'https://more-health.oss-cn-beijing.aliyuncs.com/208/drug/0.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/208/drug/1.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/208/drug/2.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/208/drug/3.png',
                'https://more-health.oss-cn-beijing.aliyuncs.com/208/drug/4.png'
            ],
            startPosition: index,
            closeable: true,
        });
        },
        async init() {
            try {
                const res = await selUserPrivilegeDetail({
                    personPrivilegeId: this.personPrivilegeId
                });
                if (res.code == 200) {
                    this.personName = res.data[0].personName;
                    this.idCardNo = res.data[0].credentialsNumber;
                    this.activationCardNumber = res.data[0].activationCardNumber;
                    this.personPhone = res.data[0].personPhone;
                } else if (res.code == 2014) {
                    // 无权益
                    this.rightsVisable = false;
                }
            } catch(err) {
                console.log('err', err);
            }
        }
    }
}
</script>
<style scoped lang="less">
.serverPdf {
    width: 100%;
    margin: 0;
    img {
        width: 100%;
    }
}
.divText {
    margin-left: 25px;
    width: 330px;
    font-size: 10px;
    line-height: 20px;
}
</style>