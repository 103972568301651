<template>
    <div class="addFamily">
        <div class="form_box">
            <div class="title">
                <div :class="publicType == '3' ? 'tit-con-yishu' : 'tit-con'">
                    <!-- <i></i> -->
                    <p>权益人信息<span>填写真实信息以及权益人本人实名制手机</span></p>
                </div>
            </div>
            <van-form @submit="onSubmit" ref="submitForm" class="submit-form">
                <van-cell-group inset>
                    <h4>姓名</h4>
                    <van-field
                        v-model="personName"
                        name="personName"
                        placeholder="请输入姓名"
                        :rules="[{ required: true, message: '请输入姓名' }]"
                    />
                    <h4>身份证号码</h4>
                    <van-field
                        v-model="credentialsNumber"
                        name="credentialsNumber"
                        placeholder="请输入身份证号"
                        :rules="[{ required: true, pattern: idCardPattern, message: '请输入正确的身份证号' }]"
                    />
                    <h4>手机号</h4>
                    <van-field
                        v-model="personPhone"
                        name="personPhone"
                        placeholder="请输入手机号"
                        :rules="[{ required: true, pattern: phonePattern, message: '请输入正确的手机号' }]"
                    />
                    <!--<h4 class="flex flex-column-center flex-between">
                        <span>潘多姆健康检测</span>
                        <van-stepper v-model="privilgeCount" />
                    </h4>-->
                    <!-- <h4>与本人关系</h4>
                    <van-field
                        name="relationType"
                        :rules="[{ validator: relativeShip, message: '请选择' }]"
                    >
                        <template #input>
                            <van-radio-group v-model="relationType" direction="horizontal">
                            <van-radio v-if="isPlus == 'true'" :class="publicType == '3' ? 'radio-yishu' : ''" :name="4">父母</van-radio>
                            <van-radio :class="publicType == '3' ? 'radio-yishu' : ''" :name="2">配偶</van-radio>
                            <van-radio :class="publicType == '3' ? 'radio-yishu' : ''" :name="3">子女</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field> -->
                </van-cell-group>
                <div class="footer">
                    <van-button v-if="publicType == 3" round block type="info" @click="submit">确认添加</van-button>
                    <van-button v-if="publicType != 3" round block type="info" @click="submit" style="background-color: #FD3D45;border: 0.02667rem solid #FD3D45;">确认添加</van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant';
import { onAddPersonFamilyNew } from '@/api';

export default {
    data() {
        return {
            personName: '',
            credentialsNumber: '',
            personPhone: '',
            idCardPattern: /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/,
            phonePattern: /^1[3456789]\d{9}$/,
            // relationType: null,
            personPrivilegeId: null,
            loading: false,
            publicType : '',
            isPlus: false,
            privilgeCount: 1
        }
    },
    created() {
        this.personPrivilegeId = this.$route.query.personPrivilegeId;
        this.isPlus = this.$route.query.isPlus
        this.publicType = localStorage.getItem('publicType');
    },
    methods: {
        submit() {
            this.$refs.submitForm.submit();
        },
        async onSubmit(values) {
            if(this.loading) {
                return ;
            }
            this.loading = true;
            console.log('submit', values);
            try {
            const res = await onAddPersonFamilyNew({
                personPrivilegeId: this.personPrivilegeId,
                personName: this.personName,
                credentialsNumber: this.credentialsNumber,
                personPhone: this.personPhone,
                privilgeCount: this.privilgeCount
                // relationType: this.relationType
            });
            if (res.code == 200) {
                Toast('添加成功!');
                this.loading = false;
                setTimeout(() => {
                    this.$router.go(-1);
                }, 2000);
            } else {
                Toast(res.msg);
                this.loading = false;
            }
          } catch(err) {
            this.loading = false;
            console.log('err', err);
            Toast(err);
          }
        },
        relativeShip() {
            return this.relationType !== null;
        }
    }
}
</script>
<style scoped lang="less">
    .addFamily {
        width: 100%;
        height: 100vh;
        padding: 16px;
        background: url(../../../assets/person-add-bg.png);
        background-size: 100% auto;
        background-repeat: no-repeat;
        .form_box {
            border-radius: 12px;
            background: #fff;
            box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.08);
            overflow: hidden;
            .title {
                padding: 20px 15px 0;
                margin-bottom: 25px;
                font-size: 15px;
                font-weight: 600;
                line-height: 22px;
                color: #000;
                display: flex;
                &>div {
                    padding-bottom: 15px;
                    border-bottom: 1px solid #E0E0E0;
                }
                span {
                    font-size: 11px;
                    color: #979BAA;
                    padding-left: 6px;
                    font-weight: 400;
                }
                .tit-con {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    i {
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 12px;
                        width: 4px;
                        height: 18px;
                        background: #005dcc;
                        border-radius: 2px;
                    }
                    span {
                        vertical-align: middle;
                    }
                }
                .tit-con-yishu {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    i {
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 12px;
                        width: 4px;
                        height: 18px;
                        background: #12cd9f;
                        border-radius: 2px;
                    }
                    span {
                        vertical-align: middle;
                    }
                }
            }
        }
        .footer {
            width: 100%;
            height: 80px;
            font-size: 18px;
            font-weight: 500;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;
            padding-bottom: 50px;
            margin-top: 150px;
        }
        .van-form {
            // padding: 0 16px;
            .van-field {
                // padding-top: 12px;
                // padding-bottom: 12px;
                .van-field__label {
                    width: 88px;
                    margin-right: 0;
                    color: #666;
                    white-space: pre-line;
                }
                /deep/ .van-field__error-message {
                    line-height: 12px;
                    position: absolute;
                    bottom: -8px;
                    left: 0;
                }
            }
        }
    }
    ::v-deep .radio-yishu .van-radio__icon--checked .van-icon{
        background-color: #FD3D45;
        border-color: #FD3D45;
    }
    ::v-deep .van-cell::after {
        border-bottom: none;
    }
    ::v-deep .van-cell.van-field {
        background-color: #F3F5F9;
        padding: 12px 10px;
        padding-bottom: 10px;
        border-radius: 8px;
        font-size: 13px;
        margin-bottom: 15px;
    }
    ::v-deep .van-hairline--top-bottom::after {
        border: none;
    }
    .submit-form {
        h4 {
            font-size: 14px;
            color: #000;
            margin-bottom: 12px;
        }
    }
</style>