<template>
    <div class="personal">
        <div class="personal-header">
            <img src="@/assets/avatar.png" />
            <div style="font-size: 12px;">当前登录手机号:{{userPhone}}</div>
        </div>
        <div class="personal-content">
            <van-cell is-link @click="goPage('rightsPersonList')">
                <template #icon>
                    <img class="icon" src="@/assets/center1.png" />
                </template>
                <template #title>
                    <span class="custom-title">我的权益</span>
                </template>
            </van-cell>
            <van-cell is-link @click="goPage('appointmentList')">
                <template #icon>
                    <img class="icon" src="@/assets/record.png" />
                </template>
                <template #title>
                    <span class="custom-title">我的预约</span>
                </template>
            </van-cell>
        </div>
        <div class="logoutDiv" @click="logout()">
            <img class="icon" src="@/assets/logout.png" />
            <p>退出登录</p>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant';
import { selUserLoginInfo,loginOut } from '@/api';
export default {
    name: 'personal',
    data() {
        return {
            userPhone: null,
            publicType: null,
            jumpUrl: null,
        }
    },
    created() {
        this.publicType = localStorage.getItem('publicType');
        this.init();
    },
    methods: {
        goPage(page) {
            if(this.publicType == 1 && page == 'appointmentList'){
                if(this.jumpUrl != null){
                    window.location.href = this.jumpUrl;
                }else{
                    Toast('您暂时没有加入潘多姆健康计划');
                }
            }else{
                this.$router.push(page);
            }
        },
        async logout(){
            const res = await loginOut({
                publicType : this.publicType
            });
            if (res.code == 200) {
                localStorage.removeItem('token');
                this.goPage('login');
            }
        },
        async init() {
            const res = await selUserLoginInfo({
                publicType : 1
            });
            if (res.code == 200) {
                this.userPhone = res.data.userPhone;
                this.jumpUrl = res.data.pdmJumpUrl;
            }
        }
    }
}
</script>
<style lang="less" scoped>
.personal {
    &-header {
        width: 100%;
        height: 240px;
        background: url(../../assets/center-bg.png);
        background-size: 100%;
        text-align: center;
        img {
            width: 80px;
            margin: 40px auto;
        }
    }
    &-content {
        width: 90%;
        margin: -20px auto 0;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .1);
        .icon {
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }
        .van-cell {
            font-size: 16px;
            padding: 20px 30px;
            align-items: center;
        }
    }
}
.logoutDiv{
    position: fixed;
    left: 140px;
    bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    p{
        color: #ccc;
        font-size: 15px;
    }
}
.icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}
</style>