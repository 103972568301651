<template>
    <div class="login">
        <div class="banner">
            <img src="@/assets/login_banner_new.png" />
            <p class="banner-title">健康管理服务平台</p>
            <p class="banner-subtitle">全面 快捷 专业一站式健康守护方案</p>
        </div>
        <div class="login-form">
            <div class="login-form_content">
                <van-form @submit="onSubmit">
                    <p class="title">验证码登录</p>
                    <van-field
                        v-model="mobile"
                        center
                        clearable
                        placeholder="请输入手机号"
                        :rules="[{ required: true, message: '请输入手机号' }]"
                        >
                        <!-- <template #left-icon class="flex">
                            <img class="safe" src="@/assets/mobile.png" />
                        </template> -->
                    </van-field>
                    <van-field
                        v-model="sms"
                        center
                        clearable
                        maxlength="6"
                        placeholder="请输入验证码"
                        :rules="[{ required: true, message: '请输入验证码' }]"
                        >
                        <!-- <template #left-icon class="flex">
                            <img class="safe" src="@/assets/safe.png" />
                        </template> -->
                        <template #button>
                            <span class="valid-text" @click="onHandleGetMessage">{{ btnText }}</span>
                        </template>
                    </van-field>
                    <div class="agreement">
                        <van-checkbox v-model="checked" checked-color="#FF2025" icon-size="16px"></van-checkbox>
                        <div>
                            <span class="gray">我已阅读并同意</span>
                            <span v-for="(item, index) in agreementList" :key="index" @click="onHanldeShowAgreement(index)">{{ item.lable }}</span>
                        </div>
                    </div>
                    <div>
                        <van-button class="login-btn" round block type="info" native-type="submit">登录</van-button>
                    </div>
                </van-form>
            </div>
        </div>
        <van-popup
            v-model="showFlag"
            round
            closeable
            position="bottom"
            close-icon="close"
            :style="{ height: '70%' }">
            <div class="text-dialog flex flex-column">
                <h2>{{finnalAgreement.title}}</h2>
                <div class="text-content flex-1" v-html="finnalAgreement.content"></div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import { onFetchMessage, onLoginOrRegister } from '@/api';
import { contents } from './util';
import { Toast } from 'vant';
export default {
    data() {
        return {
            agreementList: [{
                lable: "《隐私政策》",
            }, {
                lable: "《用户注册协议》",
            }],
            finnalAgreement: null,
            mobile: '',
            sms: '',
            checked: false,
            showFlag: false,
            values: null,
            content: '',
            time: 60,
            btnStatus: true,
            btnText: '获取验证码',
            publicType: null
        }
    },
    created() {
        const token = localStorage.getItem('token');
        this.publicType = localStorage.getItem('publicType');
        this.finnalAgreement = contents[0];
        if (token) {
            this.$router.replace('/personalNew');
        }
    },
    methods: {
        onHanldeShowAgreement(index) {
            this.finnalAgreement = contents[index];
            this.showFlag = true;
        },
        async onHandleGetMessage() {
            if (!this.btnStatus) {
                return false;
            }
            if (!this.mobile) {
                Toast('请先输入手机号');
                return;
            }
            if (this.mobile.length != 11) {
                Toast('请输入正确的手机号');
                return;
            }
            try {
                const res = await onFetchMessage({
                    userPhone:this.mobile,
                    publicType: this.publicType
                });
                Toast('短信已发送');
                console.log('res', res);
                this.btnStatus = false;
                let timer = setInterval(() => {
                    if (--this.time > 0) {
                        this.btnText = `${this.time}s重新发送`;
                    } else {
                        this.btnText = '获取验证码';
                        clearInterval(timer);
                        this.btnStatus = true;
                        this.time = 60;
                    }
                }, 1000);
            } catch(err) {
                this.btnStatus = true;
                console.log('err', err);
            }
        },
        async onSubmit() {
            if (!this.checked) {
                Toast('请阅读并同意下方协议');
                return false;
            }
            try {
                const params = {
                    userPhone: this.mobile,
                    securityCode: this.sms,
                    publicType: localStorage.getItem('publicType')
                };
                let code = localStorage.getItem('code');
                if (code) {
                    Object.assign(params, {
                        code
                    })
                }
                const res = await onLoginOrRegister(params);
                if (res.code == 200) {
                    localStorage.setItem('token', res.data.token);
                    localStorage.setItem('loginUrl', '/loginNew');
                    let url = localStorage.getItem('redirectUrl') || '/personalNew?publicType=1';
                    this.$router.push(url);
                    localStorage.removeItem('redirectUrl');
                } else {
                    Toast(res.msg);
                }
            } catch(err) {
                console.log('err', err);
            }
        },
        onShow(type) {
            this.showFlag = true;
            if(this.publicType == 3){
                this.content = contentspublic3[type];
            }else{
                this.content = contents[type];
            }
           
        }
    }
}
</script>
<style scoped lang="less">
.login .login-form {
    position: relative;
    &_content {
        background-color: #fff;
        transform: translateY(-40px);
        border-radius: 20px 20px 0 0;
        padding: 30px 40px;
    }
}
.login .banner {
    position: relative;
    width: 100%;
    margin: 0;
    img {
        width: 100%;
    }
    &-title {
        position: absolute;
        font-size: 28px;
        font-weight: bold;
        color: #fff;
        bottom: 70px;
        left: 30px;
    }
    &-subtitle {
        position: absolute;
        font-size: 14px;
        font-weight: bold;
        color: rgba(255, 255, 255, .6);
        bottom: 115px;
        left: 30px;
    }
}
.login .logo img {
    width: 100%;
}
.login .title {
    font-size: 16px;
    margin-bottom: 40px;
    font-weight: 600;
}
.van-cell {
    // position: relative;
    // border: 0.5px solid #e4e4e4;
    // border-radius: 30px;
    padding-left: 0;
    margin-bottom: 20px;
    line-height: 25px;
}
::v-deep .van-field__left-icon {
    display: flex;
    align-items: center;
}
.safe {
    width: 18px;
}
.valid-text {
    color: #FF2025;
    font-size: 14px;
}
::v-deep .van-field__control {
    color: #666 !important;
}
::v-deep .van-field__error-message {
    position: absolute;
    line-height: 12px;
    bottom: -8px;
}
.login-btn {
    font-size: 16px;
    margin: 30px 0 20px;
    font-weight: bold;
    background-color: #fd3d44;
    border-color: #fd3d44;
}
.agreement {
    margin-top: 20px;
    align-items: flex-start;
    font-size: 12px;
    color: #1d1d1d;
}
.agreement .text-link {
    color:rgb(0, 93, 204);
}
.text-dialog {
    height: 100%;
    color: #333;
    h2{
        font-size: 16px;
        color: #333;
        margin: 0 auto;
        padding: 20px 0;
        text-align: center;
    }
    .text-content {
        padding: 0 10px 30px;
        font-size: 14px;
        line-height: 22px;
        overflow: auto;
    }
}
::v-deep table {
    width: 100% !important;
}
.agreement {
    font-size: 12px;
    display: flex;
    align-items: center;
    .gray {
        color: rgba(0, 0, 0, .6);
        padding: 0 6px;
    }
}
</style>