<template>
  <div class="pdm">
    <div class="container">
      <div class="banner">
        <img src="@/assets/pdm/banner.png" />
      </div>
      <div class="card">
        <div class="card-header">
          <img src="@/assets/pdm/card-header_orginazition.png" />
        </div>
        <div class="card_info">
          <van-form ref="form1">
            <van-field
              v-if="orgId != null"
              v-model="saleOrgName"
              type="saleOrgName"
              name="saleOrgName"
              label="机构名称"
              readonly
            >
            </van-field>
            <van-field
              v-if="orgId != null"
              v-model="salePersonName"
              type="salePersonName"
              name="salePersonName"
              label="业务员姓名"
              :onkeyup="(salePersonName = salePersonName.replace(/\s+/g, ''))"
              :rules="[{ required: true, message: '请填写业务员姓名' }]"
            >
            </van-field>
            <van-field
              v-if="orgId != null"
              v-model="salePersonNo"
              type="salePersonNo"
              name="salePersonNo"
              label="业务员工号"
              :onkeyup="(salePersonNo = salePersonNo.replace(/\s+/g, ''))"
              :rules="[
                {
                  required: true,
                  pattern: salePersonNoPattern,
                  message: '请填写14位数字业务员工号',
                },
              ]"
            >
            </van-field>
            <van-field
              v-if="orgId != null"
              v-model="salePersonPhone"
              type="salePersonPhone"
              name="salePersonPhone"
              label="业务员手机号"
              :onkeyup="(salePersonPhone = salePersonPhone.replace(/\s+/g, ''))"
              :rules="[{ required: true, message: '请填写业务员手机号' }]"
            >
            </van-field>
          </van-form>
        </div>
        <div class="card-footer"></div>
        <div class="card-part">
          <div class="card-header">
            <img src="@/assets/pdm/card-header_customer.png" />
          </div>
          <div class="card_info">
            <p class="tips">*填写客户真实信息以及客户本人实名制手机</p>
            <van-form ref="form">
              <van-field
                v-model="personName"
                type="personName"
                name="personName"
                label="客户姓名"
                :onkeyup="(personName = personName.replace(/\s+/g, ''))"
                :rules="[{ required: true, message: '请填写客户姓名' }]"
              />
              <van-field
                v-model="personIdCard"
                type="personIdCard"
                name="personIdCard"
                label="客户身份证号"
                :onkeyup="(personIdCard = personIdCard.replace(/\s+/g, ''))"
                :rules="[{ required: true, message: '请填写客户身份证号' }]"
              >
              </van-field>
              <van-field
                v-model="personPhone"
                type="personPhone"
                name="personPhone"
                label="客户手机号"
                :onkeyup="(personPhone = personPhone.replace(/\s+/g, ''))"
                :rules="[
                  { required: true, message: '请填写客户本人实名制手机号' },
                ]"
              >
              </van-field>
              <van-field
                v-model="securityCode"
                center
                clearable
                label="客户验证码"
                maxlength="6"
                :onkeyup="(securityCode = securityCode.replace(/\s+/g, ''))"
                :rules="[{ required: true, message: '请填写验证码' }]"
              >
                <template #button>
                  <span
                    :class="
                      publicType == '3' ? 'btn-verify-yishu' : 'btn-verify'
                    "
                    @click="onHandleGetMessage"
                    >{{ btnText }}</span
                  >
                </template>
              </van-field>
              <div
                style="height: 100%"
                v-for="(item, itemIndex) in supportProduct"
                :key="itemIndex"
              >
                <div class="card_fee_div flex flex-between">
                  <div class="card_fee">{{ item.productName }}</div>
                  <van-stepper
                    min="0"
                    @change="calcPrice(item.productId)"
                    v-model="selectCountArr[itemIndex]"
                    input-width="40px"
                    button-size="24px"
                  />
                </div>
                <div class="card_fee_div">
                  <div class="card_fee money">¥ {{ item.price }} / 次</div>
                </div>
              </div>
            </van-form>
          </div>
          <div class="card-footer bg2"></div>
        </div>
      </div>
      <div class="btn">
        <div v-if="supportProduct.length != 0">
          <div class="pay-money">
            应付金额：<span>¥ {{ price }}</span>
          </div>
          <div>
            <img
              src="@/assets/pdm/button.png"
              :loading="loading"
              @click="onHandleCheckForm"
            />
          </div>
        </div>
      </div>
      <van-overlay :show="loading">
        <van-loading size="40" vertical class="loadingDiv"
          >支付中...</van-loading
        >
      </van-overlay>
    </div>
  </div>
</template>
<script>
import { Toast, Dialog, Loading, Overlay } from "vant";
import { areaList } from "@vant/area-data";
import {
  onFetchMessage,
  selPayOrgInfoByOrgId,
  getPayConfigByWechatPdm,
  selectPayState,
  getSupportProductByAdminUserId,
} from "@/api";
import { weixinInit } from "@/utils/wechatUtil.js";
import wx from "weixin-sdk-js";
export default {
  data() {
    return {
      flag: false, // 新用户注册后，后端是否需要验证
      loading: false,
      showFormTable: false,
      showFlag: false,
      radioValue: "",
      btnDisabled: true,
      registerFlag: false, // 注册 modal
      isHealthyInform: false, // 是否弹出协议
      healthyInform: null, // 协议内容
      content: "",
      show: false,
      errorMsg: "",
      checked: false,
      areaList,
      productCode: "", // 权益卡编码
      productPassword: "", // 卡密
      productCodeRadio: "",
      personName: "",
      personIdCard: "",
      credentialsType: 1, // 身份证类型   1 身份证 2 护照 3 港澳内地通行证 4 台胞证
      credentialsTypeName: "身份证",
      credentialsNumber: "",
      personPhone: "",
      salePersonName: "",
      salePersonNo: "",
      salePersonPhone: "",
      securityCode: "",
      regionCode: "",
      cityValue: "", // 所在城市
      time: 60,
      btnStatus: true,
      btnText: "获取验证码",
      cityVisible: false, // city flag
      showPicker: false,
      columns: ["身份证"],
      agreementLists: [], // 协议列表
      currentAgreement: null, // 选中的协议,
      publicType: "",
      price: "0",
      remark: "",
      saleOrgName: "",
      urlString: "",
      orgId: "",
      supportProduct: [],
      selectCountArr: [],
      productCount: "",
      productId: "",
      salePersonNoPattern: /^\d{14}$/,
    };
  },
  created() {
    this.productCode = this.$route.query.productCode || "CAT1";
    this.publicType = 1;
    localStorage.setItem('publicType', 1);
    this.urlString = window.location.href.split("#")[0];
    this.orgId = localStorage.getItem("orgId");
    console.log("机构id" + this.orgId);
    this.init();
    this.wxConfigInit();
  },
  methods: {
    calcPrice(prdId) {
      this.productId = prdId;
      this.productCount = this.selectCountArr[0];

      const arr = this.selectCountArr.map(
        (item, index) => item * this.supportProduct[index].price
      );
      this.price = arr.reduce((cur, total) => {
        return cur + total;
      }, 0);
      this.remark = "";

      this.selectCountArr.forEach((v, i) => {
        this.remark += this.supportProduct[i].productName + "数量" + v + "  ";
      });
    },
    goPage() {
      if (this.radioValue == "1") {
        Dialog.alert({
          title: "温馨提示",
          message: "非常抱歉，您暂不符合激活条件。",
          theme: "round-button",
          confirmButtonColor: "#1989fa",
        }).then(() => {
          // on close
        });
      } else {
        // this.$router.push('/progressStep2');
        this.registerFlag = false;
        this.showFormTable = true;
        //this.onHandleFinalSumit();
      }
    },
    async wxConfigInit() {
      let weixinShare = {
        publicType: 1,
        url: this.urlString,
      };
      await weixinInit(weixinShare);
    },
    // 是否可以点击下一步
    onHandleChange() {
      this.btnDisabled = false;
    },
    onShow(index) {
      this.currentAgreement = this.agreementLists[index];
      this.showFlag = true;
    },
    // 发送验证码
    async onHandleGetMessage() {
      if (!this.btnStatus) {
        return false;
      }
      if (!this.personPhone) {
        Toast("请先输入手机号");
        return;
      }
      if (this.personPhone.length != 11) {
        Toast("请输入正确的手机号");
        return;
      }
      try {
        const res = await onFetchMessage({
          userPhone: this.personPhone,
          publicType: 1,
        });
        Toast("短信已发送");
        console.log("res", res);
        this.btnStatus = false;
        let timer = setInterval(() => {
          if (--this.time > 0) {
            this.btnText = `${this.time}s重新发送`;
          } else {
            this.btnText = "获取验证码";
            clearInterval(timer);
            this.btnStatus = true;
            this.time = 60;
          }
        }, 1000);
      } catch (err) {
        this.btnStatus = true;
        console.log("err", err);
      }
    },
    async init() {
      try {
        let token = localStorage.getItem("token");
        let code = localStorage.getItem("code");
        let orgIdParam = this.orgId;
        let orgPayInfo = "";
        if (code) {
          const res = await selPayOrgInfoByOrgId({
            orgId: orgIdParam,
          });
          if (res.code == 200) {
            orgPayInfo = res.data;
          }
        }
        if (orgPayInfo != "") {
          this.saleOrgName = orgPayInfo.saleOrgName;
        }
      } catch (err) {
        console.log("err", err);
      }
      if (this.orgId != null && this.orgId != "") {
        try {
          let orgIdParam = this.orgId;
          const res = await getSupportProductByAdminUserId({
            adminUserId: orgIdParam,
          });
          if (res.code == 200) {
            this.supportProduct = res.data;
            this.supportProduct.forEach((v, i) => {
              this.selectCountArr[i] = 0;
            });
          }
        } catch (err) {
          console.log("err", err);
        }
      }
    },
    onHandleCheckForm() {
      if (this.price == 0) {
        Toast("应付金额不能为0");
        return false;
      }
      if (this.orgId == null || this.orgId == "") {
        Toast("当前获取到的机构信息为空，请重新扫码进入支付");
        return false;
      }
      if (!this.salePersonName) {
        Toast("业务员姓名为空，请检查");
        return false;
      }
      if (!this.salePersonNo) {
        Toast("业务员工号为空，请检查");
        return false;
      }
      if (!this.salePersonPhone) {
        Toast("业务员手机号为空，请检查");
        return false;
      }

      if (!this.personPhone) {
        Toast("客户手机号未填写，请检查");
        return false;
      }
      if (!this.personName) {
        Toast("客户姓名未填写，请检查");
        return false;
      }
      if (!this.personIdCard) {
        Toast("客户身份证号码未填写，请检查");
        return false;
      }
      if (!this.securityCode) {
        Toast("短信验证码未填写，请检查");
        return false;
      }

      this.loading = true;
      this.$refs.form.submit();
      this.$refs.form1.submit();

      const token = localStorage.getItem("token");
      console.log("token", token);
      this.onSubmit();
    },
    // 提交表单
    async onSubmit() {
      this.onHandleFinalSumit();
    },
    //唤起微信支付
    async weixinPay(weixinPayParam) {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
      } else {
        this.onBridgeReady(weixinPayParam);
      }
    },
    async onBridgeReady(weixinPayParam) {
      let payNoDiy = weixinPayParam.payNo;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: weixinPayParam.appId,
          timeStamp: weixinPayParam.timeStamp,
          nonceStr: weixinPayParam.nonceStr,
          package: weixinPayParam.packages,
          signType: weixinPayParam.signType,
          paySign: weixinPayParam.paySign,
        },
        function (res) {
          window.location.href =
            "https://yisheng.healthmore.net/paysuccess?payNo=" + payNoDiy;
          //this.$router.push(`/paysuccess?payNo=${payNoDiy}`);
        }
      );
    },
    // 提交接口
    async onHandleFinalSumit() {
      console.log("业务员手机号" + this.payPersonPhone);
      let code = localStorage.getItem("code");
      let values = {
        personName: this.personName,
        personIdCard: this.personIdCard,
        personPhone: this.personPhone,
        saleOrgName: this.saleOrgName,
        saleOrgId: this.orgId,
        price: this.price,
        remark: this.remark,
        publicType: 1,
        wxCode: code,
        securityCode: this.securityCode,
        productId: this.productId,
        count: this.productCount,
        payPersonName: this.salePersonName,
        payPersonPhone: this.salePersonPhone,
        payPersonNo: this.salePersonNo,
      };
      console.log("submit", values);
      try {
        const res = await getPayConfigByWechatPdm(values);
        console.log("res", res);
        if (res.code == 200) {
          this.weixinPay(res.data);
        } else {
          this.$toast(res.msg);
          this.loading = false;
        }
      } catch (err) {
        console.log("err", err);
        this.loading = false;
      }
      this.registerFlag = false;
    },
    onCitySelect(item) {
      console.log("item", item);
    },
  },
};
</script>
<style lang="less" scoped>
.pdm {
  // padding-top: 20px;
  background: #fb3b4b;
  height: 100vh;
  overflow-y: auto;
  .container {
    height: 1150px;
  }
}
::v-deep .health-content {
  padding: 10px 10px;
  font-size: 14px;
  line-height: 16px;
  h2 {
    font-size: 16px;
    color: #333;
    margin: 0 auto;
    padding: 20px 0;
    text-align: center;
  }
  img {
    width: 100%;
  }
}
::v-deep .health-options {
  padding: 10px;
  .explian {
    font-size: 14px;
    span {
      padding-right: 10px;
    }
  }
  .btn-options {
    margin-top: 25px;
  }
}
p {
  margin: 0;
  padding: 0;
}
.banner {
  img {
    width: 100%;
    transform: translateY(-50px);
  }
}
.tel_phone {
  font-size: 25px;
}
.minipro {
  display: flex;
  justify-content: center;
  margin: 0 auto 20px;
  .stepList {
    flex: 1;
    position: relative;
    .num {
      background: #fff;
      border-radius: 50%;
      color: #ccc;
      border: 1px solid #ccc;
    }
    .active_num,
    .num {
      width: 30px;
      height: 30px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      line-height: 30px;
      margin: 0 auto;
    }
    .active_num {
      background: #005dcc;
      border-radius: 50%;
      color: #fff;
    }
    .active_name,
    .name {
      font-size: 12px;
      font-weight: 500;
      margin-top: 7px;
      text-align: center;
      line-height: 18px;
    }
    .active_name {
      color: #005dcc;
      font-size: 20px;
    }
    img {
      width: 12px;
      position: absolute;
      right: -6px;
      top: 10px;
      border-style: none;
    }
  }
}
.form-box {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 4%);
  margin-bottom: 13px;
}
.card_fee_div {
  // height:50px;
}
.card_fee {
  font-size: 15px;
  font-weight: 600;
  color: #ffedbb;
  &.money {
    font-size: 18px;
  }
}
.card {
  padding: 0 15px;
  transform: translateY(-120px);
  &-header {
    img {
      width: 100%;
    }
  }
  &_info {
    width: 100%;
    background: url(../../assets/pdm/card-info_bg2.png);
    // background-repeat: repeat-y;
    background-size: 100% 100%;
    transform: translateY(-10px);
    padding-bottom: 15px;
    position: relative;
    z-index: 10;
    padding-top: 5px;
    & > p {
      margin: 0 0 15px 0;
      padding: 0 15px;
      font-size: 12px;
      color: #ffebb4;
      text-align: left;
    }
    ::v-deep .van-cell {
      background: #ee3941;
      border-radius: 9px;
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 15px;
      &::after {
        border-bottom: none;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    ::v-deep .van-field__label,
    ::v-deep .van-field__control {
      color: #ffedbb;
    }
    ::v-deep .van-field__control::-webkit-input-placeholder {
      color: #ffebb4;
    }
    ::v-deep .van-cell__value {
      color: #ffedbb;
    }
    ::v-deep .van-stepper {
      background: #ee3941;
      border-radius: 8px;
      position: relative;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 25%;
        width: 1px;
        height: 50%;
        background-color: #fc494e;
      }
      &:before {
        left: 24px;
      }
      &:after {
        right: 24px;
      }
    }
    ::v-deep .van-stepper__minus,
    ::v-deep .van-stepper__plus,
    ::v-deep .van-stepper__input {
      background: #ee3941;
      color: #fff;
      position: relative;
    }
  }
  &-footer {
    transform: translateY(-80px);
    height: 109px;
    // background: url(../../assets/pdm/card-footer_customer.png);
    background: url(../../assets/pdm/card-footer_orginazition.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    &.bg2 {
      background: url(../../assets/pdm/card-footer_customer.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  &-part {
    transform: translateY(-70px);
  }
}

.card .van-form {
  padding: 0 16px;
  // .van-field {
  //     padding: 16px 0;
  // }
}
::v-deep .van-field .van-field__error-message {
  line-height: 12px;
  position: absolute;
  bottom: -10px;
  left: 0;
}
::v-deep .van-field__error-message {
  color: #ffedbb;
  font-size: 12px;
  text-align: left;
}
.btn-verify {
  display: inline-block;
  font-size: 12px;
  background-color: #ffedbb;
  color: #e8134d;
  padding: 5px 10px;
  border-radius: 30px;
  &.disabled {
    opacity: 0.7;
  }
}
.popup-list {
  padding-left: 0 !important;
  padding-right: 0 !important;
  .van-cell__title {
    color: #646566;
  }
  .van-cell__value {
    color: #323233;
  }
}
.tips {
  font-size: 12px;
  color: #999;
  margin: 12px auto;
  text-align: center;
}
.loadingDiv {
  position: absolute;
  left: 42%;
  top: 40%;
}
.agreement {
  margin-top: 20px;
  align-items: flex-start;
  font-size: 12px;
  color: #1d1d1d;
  .text-link {
    color: rgb(0, 93, 204);
  }
}

::v-deep .info-mask {
  background-color: rgba(0, 0, 0, 0.3) !important;
  &_content {
    width: 300px;
    padding: 10px 20px;
    h4 {
      margin: 0;
      padding: 10px;
      font-size: 18px;
    }
    p {
      margin-bottom: 10px;
      font-size: 14px;
      text-align: center;
    }
    .qrcode {
      width: 150px;
      height: 150px;
      margin: 10px auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.text-dialog {
  height: 100%;
  color: #333;

  h2 {
    font-size: 16px;
    color: #333;
    margin: 0 auto;
    padding: 20px 0;
    text-align: center;
  }
  .text-content {
    padding: 30px 10px;
    font-size: 14px;
    line-height: 22px;
    overflow: auto;
  }
}
.btn {
  padding: 15px 60px;
  transform: translateY(-280px);
  .pay-money {
    font-size: 16px;
    text-align: center;
    margin: 5px 10px;
    color: #ffedbb;
  }
  img {
    width: 100%;
  }
}
</style>
